import { MarkEmailRead, MarkEmailUnread, NotificationsNoneRounded, NotificationsOffOutlined } from "@mui/icons-material";
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { Badge, Button, ButtonGroup, Divider, Drawer, ListItem, ListItemIcon, ListItemText, Popover, Stack, Typography } from "@mui/material";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import EmptyPage from "../../components/EmptyPage";
import { DateToFormattedString } from "../../components/Logic/DateLogic";
import TooltipComponent from "../../components/TooltipComponent";
import { PaginationQueryPackage } from "../../models/API/QueryParams/PaginationQueryPackage";
import { NotificationPatch, NotificationResponse } from "../../models/Notification";
import { GetManyPackage } from "../../redux/GetManyPackage";
import { useGetNotificationsByUserIdQuery, useUpdateNotificationMutation } from "./redux/notificationApiSlice";

export function NotificationView() {
    const oneMinuteInMs = 60000;
    const { t } = useTranslation()
    const { data, error } = useGetNotificationsByUserIdQuery({
        pagination: new PaginationQueryPackage({ page: 1, pagesize: 1 })
    }, { pollingInterval: oneMinuteInMs / 2 });

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [openShowAll, setOpenShowAll] = useState(false)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        document.title = t("Aftaleportal") + (data?.un_read && data?.un_read != 0 ? ` (${data?.un_read})` : "");
    }, [data?.un_read]);

    if (error)
        return (<TooltipComponent title={t("An error occured") + ": " + JSON.stringify(error)}><CloudOffIcon /></TooltipComponent>)

    return (
        <>

            <Button onClick={handleClick}>
                <Badge key={data?.notifications?.length} badgeContent={data?.un_read} color="warning">
                    <NotificationsNoneRounded sx={{ color: "white" }} />
                </Badge>
            </Button>
            <Drawer
                anchor="right"
                open={openShowAll}
                onClose={() => { setOpenShowAll(false) }}
            >
                <ListItem>
                    <ListItemText>
                        <Typography>{t(t("Notifications"))}</Typography>
                    </ListItemText>
                </ListItem>
                <Divider />
                <NotificationList getNotifications={useGetNotificationsByUserIdQuery} />
            </Drawer>
            <Popover
                open={anchorEl != undefined}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ maxWidth: "50em" }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <ListItem>
                    <ListItemText>
                        <Typography>{t(t("Notifications"))}</Typography>
                    </ListItemText>
                </ListItem>
                <Divider />
                <NotificationList getNotifications={useGetNotificationsByUserIdQuery} />
                <Divider />
                <ListItem>
                    <ListItemText>
                        <Button onClick={() => { setAnchorEl(null); setOpenShowAll(true) }}>
                            {t("Show all")}
                        </Button>
                    </ListItemText>
                </ListItem>
            </Popover>
        </>
    )


}

function NotificationList(props: { page?: number, size?: number, getNotifications: UseQuery<QueryDefinition<GetManyPackage<object>, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>, any, NotificationResponse, any>> }) {
    const { t, i18n } = useTranslation()
    const updateNotification = useUpdateNotificationMutation()

    const oneMinuteInMs = 60000;
    const getNotifications = props.getNotifications({
        pagination: new PaginationQueryPackage({ page: props.page, pagesize: props.size })
    }, { pollingInterval: oneMinuteInMs / 2 });

    const data = getNotifications.data;
    return (
        <>
            {data?.notifications?.length == 0 ? <EmptyPage elevation={0} typographyVariant="body2" icon={NotificationsOffOutlined} /> :
                data?.notifications?.map(notification => {
                    return (
                        <>
                            <ListItem alignItems="flex-start" selected={!notification.is_read}>
                                <ListItemIcon >
                                    <TooltipComponent title={notification.is_read ? t("Read") : t("Unread")}>
                                        {notification.is_read ? <MarkEmailRead /> : <MarkEmailUnread />}
                                    </TooltipComponent>
                                </ListItemIcon>
                                <ListItemText
                                    primary={i18n.language == "da" ? notification.da_description : notification.en_description}
                                    secondary={
                                        <Stack>
                                            {notification.created_by ?
                                                <Typography variant="body2">{t("Notification created by", {user: notification.created_by.email, organisation: notification.created_by.organisation?.name})}</Typography>
                                            : <></>}
                                            <Typography variant="body2">{DateToFormattedString(notification.created_time)}</Typography>
                                            <ButtonGroup>
                                                <Button variant="text" sx={{ p: 0 }} onClick={notification.is_read ?
                                                    () => {
                                                        updateNotification[0]({
                                                            data: { uuid: notification.uuid, is_read: false } as NotificationPatch,
                                                            parentId: notification.uuid
                                                        })
                                                    } :
                                                    () => {
                                                        updateNotification[0]({
                                                            data: { uuid: notification.uuid, is_read: true } as NotificationPatch,
                                                            parentId: notification.uuid
                                                        })
                                                    }}>
                                                    <Typography variant="caption">{notification.is_read ? t("Mark as unread") : t("Mark as read")}</Typography>

                                                </Button>

                                            </ButtonGroup>
                                            {notification.agreement_uuid ?
                                                <ButtonGroup>
                                                    <Button
                                                        sx={{ p: 0 }}
                                                        variant="text"
                                                        onClick={(e: any) => e.stopPropagation()}
                                                        component={Link}
                                                        to={`/agreements/${notification.agreement_uuid}`}
                                                        target="_blank"
                                                    >
                                                        <TooltipComponent title={t("Open in new tab")}>
                                                            <Typography variant="caption">{t("View notification")}</Typography>
                                                        </TooltipComponent>
                                                    </Button >
                                                </ButtonGroup>
                                                : <></>}
                                        </Stack>
                                    }
                                />
                            </ListItem>
                        </>
                    )
                })
            }
        </>

    )
}
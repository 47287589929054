
import getEnvironment from '../../../env'
import Event from '../../../models/Event'
import HandleQuery from '../../../redux/EndpointQueryHandler';
import { GetManyPackage } from '../../../redux/GetManyPackage';
import handleResponse from '../../../redux/handleResponse';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { transformMultipleResponseToRightType } from '../../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;

export const eventsApiSlice = portalApiSlice.injectEndpoints({ 
  endpoints: (builder) => ({
    getAllEvents: builder.query<Event[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/audits`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Events could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: Event[], b, c) => transformMultipleResponseToRightType(Event, a, b, c),
      providesTags: (result = [], _error, _arg) => [
        'events',
        ...result.map(({ uuid }) => ({ type: 'events', uuid: uuid }) as const)
      ]
    }),
  }),
})


export const { useGetAllEventsQuery } = eventsApiSlice

















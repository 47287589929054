import { Box } from "@mui/material";
import { FormikProps } from "formik";
import * as Yup from 'yup';
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import EasyFormMany from "../../../components/Forms/EasyFormMany";
import { EasyFormSideBySide } from "../../../components/Forms/FormLayout";
import { FieldEditorRender } from "../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import BaseModel from "../../../models/BaseModels/BaseModel";
import { CreateOrganisationHostingSiteRequest, CreateOrganisationRequest } from "../../../models/Organisation";
import { getOrganisationInfoColums } from "./BaseInfoStep";
import { getCPEColumns } from "./CPEStep";
import { getHostingSiteColums } from "./HostingSiteStep";
import { getSubnetColumns } from "./SubnetStep";
import { getDnsColumns } from "./DnsSuffixStep";
import { getRouterColumns } from "./RouterStep";
import getOrganisationFoundationColumns from "../organisationFoundationColumns";

export function getOverviewStepValidation() {
    return Yup.object()
}

export default function OrganisationOverviewStep(props: { formikProps: FormikProps<CreateOrganisationRequest>, formPropsStuf: any, displayed: boolean }) {
    return (
        !props.displayed ? <></> :
            <Box display="flex" flexDirection={"column"} rowGap={5} >
                <EasyFormSideBySide
                    {...props.formPropsStuf}
                    {...props.formikProps}
                    columns={getOrganisationFoundationColumns("organisationFoundation.")}
                    editMode={false}
                />

                <EasyFormSideBySide
                    {...props.formPropsStuf}
                    {...props.formikProps}
                    columns={getOrganisationInfoColums()}
                    editMode={false}
                />

                <OverviewList
                    title="Hosting sites"
                    values={props.formikProps.values.hosting_sites}
                    getFieldRows={(hostingSites: CreateOrganisationHostingSiteRequest[]) => 
                        hostingSites?.map((hostingSite) => getHostingSiteColums(hostingSite.uuid)?.map(column => mapColumn(column)))}
                />

                <OverviewList
                    title="CPE"
                    values={props.formikProps.values.cpes.map(cpe => ({...cpe, hosting_site: cpe.hosting_site?.name}))}
                    getFieldRows={(cpes: BaseModel[]) => 
                        cpes?.map((cpe) => getCPEColumns(props.formikProps.values.hosting_sites, cpe.uuid)?.map(column => mapColumn(column)))}
                />

                <OverviewList
                    title="Subnets"
                    values={props.formikProps.values.subnets.map(subnet => ({...subnet, hosting_site: subnet.hosting_site?.name}))}
                    getFieldRows={(subnets: BaseModel[]) => 
                        subnets?.map((subnet) => getSubnetColumns(props.formikProps.values.hosting_sites, subnet.uuid)?.map(column => mapColumn(column)))}
                />

                <OverviewList
                    title="DNS suffixes"
                    values={props.formikProps.values.dnsSuffixes.map(dnsSuffix => ({...dnsSuffix, hosting_site: dnsSuffix.hosting_site?.name}))}
                    getFieldRows={(dnsSuffixes: BaseModel[]) => 
                        dnsSuffixes?.map((dnsSuffix) => getDnsColumns(props.formikProps.values.hosting_sites, dnsSuffix.uuid)?.map(column => mapColumn(column)))}
                />

                <OverviewList
                    title="Routers"
                    values={props.formikProps.values.routers}
                    getFieldRows={(routers: BaseModel[]) => 
                        routers?.map((router) => getRouterColumns(router.uuid)?.map(column => mapColumn(column)))}
                />
            </Box>
    )
}

function OverviewList<T extends BaseModel>(props: { title: string, values: T[], getFieldRows: (values: T[]) => Array<FieldEditorRender[]> }) {
    return (
        <EasyFormMany
            isAllowedToDelete={() => false}
            isAllowedToEdit={() => false}
            isAllowedToCreate={() => false}
            ressourceOwnerId=""
            arrayPath=""
            confirmDeletionText=""
            initialValue={props.values}
            SignupSchema={getOverviewStepValidation()}
            idToFetch=""
            columns={[
                new EasyFormColumn(props.title, "", [])
            ]}
            getFieldRows={props.getFieldRows}
        />
    )
}

function mapColumn(column: FieldEditorRender): FieldEditorRender {
    return new TextFieldRender({
        displayText: column.displayText,
        fieldName: "",
        nestedfieldName: column.nestedfieldName,
        type: "text",
        uniqueId: column.uniqueId
    })
}
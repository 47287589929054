import { t } from "i18next";
import * as Yup from 'yup';

export function getOrganisationFoundationValidationScheme() {
    return Yup.object().shape({
        cvr: Yup.string().max(100),
        ceo_name: Yup.string().max(100),
        ceo_phone: Yup.string().max(100).phoneNumber(),
        ceo_email: Yup.string().max(100).email(t('Invalid email')),
        security_manager_name: Yup.string().max(100),
        security_manager_phone: Yup.string().max(100).phoneNumber(),
        security_manager_email: Yup.string().max(100).email(t('Invalid email')),
        organisational_manager_name: Yup.string().max(100),
        organisational_manager_phone: Yup.string().max(100).phoneNumber(),
        organisational_manager_email: Yup.string().max(100).email(t('Invalid email')),
        financial_manager_name: Yup.string().max(100),
        financial_manager_phone: Yup.string().max(100).phoneNumber(),
        financial_manager_email: Yup.string().max(100).email(t('Invalid email')),
        service_desk_phone: Yup.string().max(100).phoneNumber(),
        service_desk_email: Yup.string().max(100).email(t('Invalid email')),
    })
}
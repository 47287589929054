
import getEnvironment from '../../../env';
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper';
import { SelfResponse, UserCopyRequest, UserPOST, UserResponse } from '../../../models/User';
import { CreateResponse } from '../../../redux/CreateResponse';
import HandleQuery from '../../../redux/EndpointQueryHandler';
import { GetManyPackage } from '../../../redux/GetManyPackage';
import handleResponse from '../../../redux/handleResponse';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;
// Define a users using a base URL and expected endpoints
export const usersApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUser: builder.query<UserResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/users`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Users could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: UserResponse[], b, c) => transformMultipleResponseToRightType(UserResponse, a, b, c),
      providesTags: ['users']
    }),
    getAllUsersByOrganisation: builder.query<UserResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/organisations/${pack.uuid}/users`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Users could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: UserResponse[], b, c) => transformMultipleResponseToRightType(UserResponse, a, b, c),
      providesTags: ['users']
    }),
    getUserById: builder.query<UserResponse, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/users/${uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "User could no be fetched" }),
      }),
      transformResponse: (a: UserResponse, b, c) => transformSingleResponseToRightType(UserResponse, a, b, c),
      providesTags: ['user']
    }),
    getUserByToken: builder.query<SelfResponse, void>({
      query: () => HandleQuery({
        url: `${baseurl}/users/self`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "User could no be fetched" }),
      }),
      transformResponse: (a: SelfResponse, b, c) => transformSingleResponseToRightType(SelfResponse, a, b, c),
      providesTags: ['user'],
    }),
    updateUser: builder.mutation<UserResponse, RequestMutationWrapper<UserResponse>>({
      query: (organisation) => HandleQuery({
        url: `${baseurl}/users/${organisation.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "User was updated", toastErrorText: "User could not be updated" }),
        method: "PATCH",
        body: organisation.data
      }),
      transformResponse: (a: UserResponse, b, c) => transformSingleResponseToRightType(UserResponse, a, b, c),
      invalidatesTags: ['users', 'user']
    }),
    forceUpdateUser: builder.mutation<UserResponse, RequestMutationWrapper<UserResponse>>({
      query: (organisation) => HandleQuery({
        url: `${baseurl}/users/${organisation.data.uuid}/force`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "User was updated", toastErrorText: "User could not be updated" }),
        method: "PATCH",
        body: organisation.data
      }),
      transformResponse: (a: UserResponse, b, c) => transformSingleResponseToRightType(UserResponse, a, b, c),
      invalidatesTags: ['users', 'user', 'contacts', 'contact', 'service-contacts', 'client-contacts']
    }),
    addUser: builder.mutation<CreateResponse, RequestMutationWrapper<UserPOST>>({
      query: (userPost) => HandleQuery({
        url: `${baseurl}/organisations/${userPost.data.organisation?.uuid}/users`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "User was created", toastErrorText: "User could not be created" }),
        method: 'POST',
        body: {
          first_name: userPost.data.first_name,
          last_name: userPost.data.last_name,
          email: userPost.data.email,
          role: userPost.data.role
        }
      }),
      transformResponse: (a: UserResponse, b, c) => transformSingleResponseToRightType(UserResponse, a, b, c),

      invalidatesTags: ['users'],
    }),
    forceAddUser: builder.mutation<CreateResponse, RequestMutationWrapper<UserPOST>>({
      query: (userPost) => HandleQuery({
        url: `${baseurl}/organisations/${userPost.data.organisation?.uuid}/users/force`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "User was created", toastErrorText: "User could not be created" }),
        method: 'POST',
        body: {
          first_name: userPost.data.first_name,
          last_name: userPost.data.last_name,
          email: userPost.data.email,
          role: userPost.data.role
        }
      }),
      transformResponse: (a: UserResponse, b, c) => transformSingleResponseToRightType(UserResponse, a, b, c),

      invalidatesTags: ['users', 'contacts', 'contact', 'service-contacts', 'client-contacts']
    }),
    addMyselfUser: builder.mutation<CreateResponse, void>({
      query: () => HandleQuery({
        url: `${baseurl}/users`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false }),
        method: 'POST'
      }),
      transformResponse: (a: UserResponse, b, c) => transformSingleResponseToRightType(UserResponse, a, b, c),

      invalidatesTags: ['users'],
    }),
    forceNewPasswordForUser: builder.mutation<void, CreateResponse>({
      query: (user) => HandleQuery({
        url: `${baseurl}/users/${user.uuid}/force-password-change`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Users password will be reconfigured by the user", toastErrorText: "User could not be forced to reconfigure password", toastWithResult: true }),
        method: 'POST'
      }),
      invalidatesTags: ['users'],
    }),
    forceNewMFAForUser: builder.mutation<void, CreateResponse>({
      query: (user) => HandleQuery({
        url: `${baseurl}/users/${user.uuid}/force-totp-change`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Users two-factor will be reconfigured by the user", toastErrorText: "User could not be forced to reconfigure two-factor", toastWithResult: true }),
        method: 'POST'
      }),
      invalidatesTags: ['users'],
    }),
    updateSslVpnAccessForUser: builder.mutation<void, {uuid: string, access: boolean}>({
      query: (input) => HandleQuery({
        url: `${baseurl}/users/${input.uuid}/ssl-vpn-access`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "SSL VPN access updated for the user", toastErrorText: "SSL VPN access could not be updated for the user", toastWithResult: true }),
        method: 'PATCH',
        body: {
          ssl_vpn_access: input.access
        }
      }),
      invalidatesTags: ['user'],
    }),
    updateUserFrom: builder.mutation<void, RequestMutationWrapper<UserCopyRequest>>({
      query: (input) => HandleQuery({
        url: `${baseurl}/users/${input.data.user_to?.uuid}/copy-from`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "User was updated", toastErrorText: "User could not be updated", toastWithResult: true }),
        method: 'PATCH',
        body: {
          user_uuid: input.data.user_from?.uuid
        }
      }),
      invalidatesTags: ['user', 'users', 'client-contacts', 'contactlabels', 'service-approvers', 'service-contacts'],
    }),
    deleteUser: builder.mutation<void, RequestMutationWrapper<UserResponse>>({
      query: (user) => HandleQuery({
        url: `${baseurl}/users/${user.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "User was deleted!", toastErrorText: "User could not be deleted" }),
      }),

      invalidatesTags: ['users']
    }),
    sendWelcomeMail: builder.mutation<void, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/users/${uuid}/welcome`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Welcome mail was sent to the user", toastErrorText: "Welcome mail could not be sent to the user", toastWithResult: true }),
        method: 'POST'
      }),
      invalidatesTags: ['user'],
    }),
  }),
})


export const { useGetAllUserQuery, useGetUserByIdQuery, useGetAllUsersByOrganisationQuery, useForceNewMFAForUserMutation, useForceNewPasswordForUserMutation, useLazyGetUserByIdQuery, useLazyGetAllUserQuery, useLazyGetAllUsersByOrganisationQuery, useAddMyselfUserMutation, useDeleteUserMutation, useUpdateUserMutation, useForceUpdateUserMutation, useAddUserMutation, useForceAddUserMutation, useSendWelcomeMailMutation, useUpdateSslVpnAccessForUserMutation, useUpdateUserFromMutation } = usersApiSlice

import getEnvironment from '../../../env'
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper'
import { CreateOrganisationAuthorization } from '../../../models/Organisation'
import { UserAuthorisationResponse } from '../../../models/User'
import { CreateResponse } from '../../../redux/CreateResponse'
import HandleQuery from '../../../redux/EndpointQueryHandler'
import { GetManyPackage } from '../../../redux/GetManyPackage'
import handleResponse from '../../../redux/handleResponse'
import { portalApiSlice } from '../../../redux/portalApiSlice'
import { transformMultipleResponseToRightType, transformSingleResponseToRightType } from '../../../redux/transformResponse'

const baseurl = getEnvironment().REACT_APP_API_BASEURL;

export const authorizationApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAuthorizationsForUser: builder.query<UserAuthorisationResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/users/${pack.uuid}/authorisations`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Users could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: UserAuthorisationResponse[], b, c) => transformMultipleResponseToRightType(UserAuthorisationResponse, a, b, c),
      providesTags: ["authorisations"]
    }),
    getAuthorizationsForOrganisation: builder.query<UserAuthorisationResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/organisations/${pack.uuid}/authorisations`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Authorisations could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: UserAuthorisationResponse[], b, c) => transformMultipleResponseToRightType(UserAuthorisationResponse, a, b, c),
      providesTags: ['authorisations']
    }),
    getAllAuthorizations: builder.query<UserAuthorisationResponse[], GetManyPackage>({
      query: (pack) => HandleQuery({
        url: `${baseurl}/authorisations`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Authorisations could not be fetched" }),
        pagination: pack.pagination
      }),
      transformResponse: (a: UserAuthorisationResponse[], b, c) => transformMultipleResponseToRightType(UserAuthorisationResponse, a, b, c),
      providesTags: ['authorisations']
    }),
    getAuthorizationById: builder.query<UserAuthorisationResponse, string>({
      query: (uuid) => HandleQuery({
        url: `${baseurl}/authorisations/${uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastWithResult: false, toastErrorText: "Authorisation could no be fetched" }),
      }),
      transformResponse: (a: UserAuthorisationResponse, b, c) => transformSingleResponseToRightType(UserAuthorisationResponse, a, b, c),
      providesTags: ['authorisation']
    }),
    updateAuthorization: builder.mutation<void, RequestMutationWrapper<UserAuthorisationResponse>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/authorisations/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Authorization was updated!", toastErrorText: "Authorization could not be updated" }),
        method: "PATCH",
        body: { role: request.data.role }
      }),
      invalidatesTags: ['authorisations', 'service-approvers', 'authorisation']
    }),
    deleteAuthorization: builder.mutation<void, RequestMutationWrapper<CreateOrganisationAuthorization>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/authorisations/${request.data.uuid}`,
        method: 'DELETE',
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Authorization was deleted!", toastErrorText: "Authorization could not be deleted" }),
      }),

      invalidatesTags: ['authorisations', 'service-approvers']
    }),
    addAuthorization: builder.mutation<CreateResponse, RequestMutationWrapper<CreateOrganisationAuthorization>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/users/${request.data.parentUser?.uuid}/authorisations`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Authorization was created", toastErrorText: "Authorization could not be created" }),
        method: 'POST',
        body: {
          organisation_uuid: request.data.organisation?.uuid,
          role: request.data.role?.toString()
        },
      }),
      transformResponse: (a: CreateResponse, b, c) => transformSingleResponseToRightType(CreateResponse, a, b, c),
      invalidatesTags: ['authorisations', 'service-approvers'],
    }),
    useAuthorization: builder.mutation<void, RequestMutationWrapper<string>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/authorisations/${request.data}/use`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Authorization was used", toastErrorText: "Authorization could not be used" }),
        method: 'POST',
      }),
    }),
  }),
})



export const { useAddAuthorizationMutation, useDeleteAuthorizationMutation, useUpdateAuthorizationMutation, useGetAuthorizationsForUserQuery, useGetAuthorizationsForOrganisationQuery, useGetAllAuthorizationsQuery, useGetAuthorizationByIdQuery, useUseAuthorizationMutation, useLazyGetAllAuthorizationsQuery, useLazyGetAuthorizationsForOrganisationQuery } = authorizationApiSlice

















import BaseModel from "./BaseModels/BaseModel";

export default class OrganisationFoundation extends BaseModel {
    cvr = ""
    ceo_name = ""
    ceo_phone = ""
    ceo_email = ""
    security_manager_name = ""
    security_manager_phone = ""
    security_manager_email = ""
    organisational_manager_name = ""
    organisational_manager_phone = ""
    organisational_manager_email = ""
    financial_manager_name = ""
    financial_manager_phone = ""
    financial_manager_email = ""
    service_desk_phone = ""
    service_desk_email = ""
}
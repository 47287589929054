import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Grid, Typography } from "@mui/material";
import { FormikProps } from 'formik';
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DrawerButton from "../../components/DrawerButton";
import EasyStepper from '../../components/EasyStepper';
import Loading from '../../components/loading';
import TooltipComponent from '../../components/TooltipComponent';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { DNSSuffix } from '../../models/DNSSuffix';
import OrganisationResponse, { CreateOrganisationHostingSiteRequest, CreateOrganisationRequest, OrganisationDNSSuffix, OrganisationHostingSiteSubnet, OrganisationRouter } from "../../models/Organisation";
import { Subnet } from '../../models/Subnet';
import { CreateResponse } from '../../redux/CreateResponse';
import { PermissionEnum } from "../Permission/PermissionEnum";
import { Can } from '../UserApi/logic/Can';
import OrganisationInfoStep from './createOrganisationFlow/BaseInfoStep';
import DnsSuffixStep, { getDnsSuffixStepValidation } from './createOrganisationFlow/DnsSuffixStep';
import HostingSiteStep, { getHostingSiteStepValidation } from './createOrganisationFlow/HostingSiteStep';
import RouterStep, { getRouterStepValidation } from './createOrganisationFlow/RouterStep';
import SubnetStep, { getSubnetStepValidation } from './createOrganisationFlow/SubnetStep';
import { OrganisationInfoContent } from "./organisationButtons/details/OrganisationInfoButton";
import { getBaseOrganisationValidationScheme } from './organisationSchemeValidation';
import { useGetDnsSuffixesQuery } from './redux/dnsSuffixApiSlice';
import { useAddOrganisationMutation } from "./redux/organisationApiSlice";
import CPEStep, { getCPEValidationScheme } from './createOrganisationFlow/CPEStep';
import { CPE } from '../../models/CPE';
import OrganisationOverviewStep, { getOverviewStepValidation } from './createOrganisationFlow/OverviewStep';
import OrganisationFoundationStep, { getOrganisationFoundationStepValidationScheme } from './createOrganisationFlow/OrganisationFoundationStep';

export const CreateOrganisationButton = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(false)
    return (
        <Can I={PermissionEnum.GOD} a={OrganisationResponse} >
            <DrawerButton open={open} setOpen={setOpen} buttonContent={
                <TooltipComponent title={t("Create organisation") + ""}>
                    <AddCircleIcon />
                </TooltipComponent>
            } >
                <DrawerContent />
            </DrawerButton>
        </Can>
    )
}
export const DrawerContent = () => {
    const [submittedOrganisation, setSubmittedOrganisation] = useState<CreateResponse | undefined>(undefined)
    return (
        <>
            {submittedOrganisation == undefined ?
                <CreateOrganisationButtonContent afterSaveAction={(organisation) => setSubmittedOrganisation(organisation)} /> :
                <OrganisationInfoContent organisationUuid={submittedOrganisation.uuid} />
            }
        </>
    )
}

export interface CreateOrganisationButtonContentProps {
    afterSaveAction: (organisation: OrganisationResponse) => void
}

export const CreateOrganisationButtonContent = (props: CreateOrganisationButtonContentProps) => {
    const { t } = useTranslation()
    const addOrganisationMutation = useAddOrganisationMutation()
    const [initial, setInitial] = useState<CreateOrganisationRequest>(new CreateOrganisationRequest())
    const allDnsSuffixes = useGetDnsSuffixesQuery({
        pagination: new PaginationQueryPackage()
    });
    const [allowExistingDnsSuffixes, setAllowExistingDnsSuffixes] = useState<boolean>(false)

    useMemo(() => {
        const newOrganisation = new CreateOrganisationRequest();
        newOrganisation.contacts = []
        newOrganisation.dnsSuffixes = [new OrganisationDNSSuffix()];
        newOrganisation.hosting_sites = [new CreateOrganisationHostingSiteRequest()];
        newOrganisation.subnets = [new OrganisationHostingSiteSubnet()];
        newOrganisation.routers = [new OrganisationRouter()];
        setInitial(newOrganisation);
    }, [])

    if(allDnsSuffixes.isLoading || allDnsSuffixes.data === undefined) {
        return (<Loading />)
    }


    function mapRequestValues(values: CreateOrganisationRequest): CreateOrganisationRequest {
        values.hosting_sites.forEach((hostingSite) => {
            const subnetsForHostingSite = values.subnets
                .filter(subnet => subnet.hosting_site?.uuid === hostingSite.uuid)
                .map(subnet => {
                    const newSubnet = new Subnet();
                    newSubnet.subnet = subnet.subnet;
                    return newSubnet;
                });

            hostingSite.subnets = subnetsForHostingSite;

            const dnsSuffixesForHostingSite = values.dnsSuffixes
                .filter(dnsSuffix => dnsSuffix.hosting_site?.uuid === hostingSite.uuid)
                .map(dnsSuffix => {
                    const newDnsSuffix = new DNSSuffix();
                    newDnsSuffix.dns_suffix = dnsSuffix.dns_suffix;
                    return newDnsSuffix;
                });

            hostingSite.dns_suffixes = dnsSuffixesForHostingSite;

            const cpesForHostingSite = values.cpes
                .filter(cpe => cpe.hosting_site?.uuid === hostingSite.uuid)
                .map(cpe => {
                    const newCPE = new CPE();
                    newCPE.sdn_v4_id = cpe.sdn_v4_id;
                    newCPE.theft_mark = cpe.theft_mark;
                    newCPE.vlan_name = cpe.vlan_name;
                    newCPE.setup_type = cpe.setup_type;
                    newCPE.failover = cpe.failover;
                    return newCPE;
                });

            hostingSite.cpes = cpesForHostingSite;
        })

        return values;
    }

    async function createOrganisation(values: CreateOrganisationRequest) {
        const request = mapRequestValues(values);

        await addOrganisationMutation[0]({
            data: request
        }).unwrap()
            .then(result => props.afterSaveAction(result as OrganisationResponse))
    }

    function NextStepFunc(currentPage: number, _values: CreateOrganisationRequest) {
        return currentPage + 1
    }
    function PreviousStepFunc(currentPage: number) {
        return currentPage - 1
    }

    return (
        <>
            <Box>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("Create organisation") + ""}</Typography>

                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={10}>
                        <EasyStepper<CreateOrganisationRequest>
                            nextPage={NextStepFunc}
                            previousPage={PreviousStepFunc}
                            submit={createOrganisation}
                            initialValue={initial}
                            stepValidations={[
                                getOrganisationFoundationStepValidationScheme(),
                                getBaseOrganisationValidationScheme(),
                                getHostingSiteStepValidation(),
                                getCPEValidationScheme(),
                                getSubnetStepValidation(t),
                                getDnsSuffixStepValidation(allDnsSuffixes.data, t, allowExistingDnsSuffixes),
                                getRouterStepValidation(t),
                                getOverviewStepValidation(),
                            ]}
                            stepTitles={[
                                "Organisation foundation",
                                "Information",
                                "Hosting site",
                                "CPE",
                                "Subnet",
                                "DNS",
                                "Router",
                                "Overview",
                            ]}
                            steps={[
                                OrganisationFoundationStep,
                                OrganisationInfoStep,
                                HostingSiteStep,
                                CPEStep,
                                SubnetStep,
                                (props: { formikProps: FormikProps<CreateOrganisationRequest>; formPropsStuf: any; }) => DnsSuffixStep(props, allowExistingDnsSuffixes, setAllowExistingDnsSuffixes),
                                RouterStep,
                                OrganisationOverviewStep,
                            ]} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

import { FormikProps } from "formik";
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { EasyFormSideBySide } from "../../../components/Forms/FormLayout";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { CreateOrganisationRequest } from "../../../models/Organisation";

export default function OrganisationInfoStep(props: { formikProps: FormikProps<CreateOrganisationRequest>, formPropsStuf: any }) {
    const ceoEmailOverride = props.formikProps.values.organisationFoundation.ceo_email
    const generalContactInformationOverride = [props.formikProps.values.organisationFoundation.ceo_name, props.formikProps.values.organisationFoundation.ceo_phone]
                    .filter(value => value && value.trim() !== "").join(", ")
    const serviceDeskEmailOverride = props.formikProps.values.organisationFoundation.service_desk_email
    const serviceDeskPhoneOverride = props.formikProps.values.organisationFoundation.service_desk_phone
    return (
        <EasyFormSideBySide
            validateOnMount
            {...props.formikProps}
            {...props.formPropsStuf}
            columns={getOrganisationInfoColums(ceoEmailOverride, generalContactInformationOverride, serviceDeskEmailOverride, serviceDeskPhoneOverride)}
            editMode={true} />
    )
}

export function getOrganisationInfoColums(ceoEmailOverride?: string, generalContactInformationOverride?: string, serviceDeskEmailOverride?: string, serviceDeskPhoneOverride?: string) {
    return ([
        new EasyFormColumn("Information", "", [
            new TextFieldRender({
                displayText: "Name",
                fieldName: "name",
                type: "text",
                uniqueId: "no_id"
            }),
            new TextFieldRender({
                displayText: "Address",
                fieldName: "address",
                type: "text",
                uniqueId: "no_id"
            }),
            new TextFieldRender({
                displayText: "Description",
                fieldName: "description",
                type: "text",
                multiline: true,
                uniqueId: "no_id"
            }),
            new TextFieldRender({
                displayText: "General contact email",
                fieldName: "fallback_email",
                type: "text",
                uniqueId: "no_id",
                overrideValue: ceoEmailOverride
            }),
            new TextFieldRender({
                displayText: "General contact information",
                fieldName: "general_contact_information",
                multiline: true,
                type: "text",
                uniqueId: "no_id",
                overrideValue: generalContactInformationOverride
            }),
            new TextFieldRender({
                displayText: "Service desk email",
                fieldName: "service_desk_email",
                type: "text",
                uniqueId: "no_id",
                overrideValue: serviceDeskEmailOverride
            }),
            new TextFieldRender({
                displayText: "Service desk telephone number",
                fieldName: "service_desk_telephone_number",
                type: "tel",
                uniqueId: "no_id",
                overrideValue: serviceDeskPhoneOverride
            }),
            new TextFieldRender({
                displayText: "Hotline telephone number",
                fieldName: "hotline_telephone_number",
                type: "tel",
                uniqueId: "no_id"
            }),
        ])
    ])
}

import { FieldArray, FormikProps } from "formik";
import { TFunction, useTranslation } from "react-i18next";
import * as Yup from 'yup';
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { FormType } from "../../../components/Forms/EasyFormMany";
import { EasyFormRow } from "../../../components/Forms/FormLayout";
import { AutocompleteFieldRender } from "../../../components/Forms/Renders/AutocompleteFieldRender";
import { FieldEditorRender } from "../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { IsIpAddressValidation } from "../../../components/Forms/Validations/IsIpAddressValidation";
import HostingsiteBase from "../../../models/Hostingsite";
import { CreateOrganisationHostingSiteRequest, CreateOrganisationRequest, OrganisationHostingSiteSubnet } from "../../../models/Organisation";

export function getSubnetStepValidation(t: TFunction<"translation", undefined>) {
    return Yup.object().shape({
        subnets: Yup.array().of(Yup.object().shape({
            hosting_site: Yup.object().required(t('Required')),
            subnet: IsIpAddressValidation(true).required(t('Required')),
        }))
    })
}

export function getSubnetColumns(hostingSites: CreateOrganisationHostingSiteRequest[], uniqueId: string) {
    return ([
        new AutocompleteFieldRender({
            displayText: "Hosting site",
            fieldName: "subnets",
            nestedfieldName: "hosting_site",
            getOptionLabelFunc: (option: HostingsiteBase) => option.name ?? "",
            options: hostingSites,
            renderNonChangeable: (content) => <>{content?.toString()}</>,
            uniqueId: uniqueId,
            showLabel: true,
            columns: 12
        }),
        new TextFieldRender({
            displayText: "IP scope",
            fieldName: "subnets",
            showLabel: true,
            nestedfieldName: "subnet",
            type: "text",
            columns: 12,
            uniqueId: uniqueId
        })
    ])
}

export default function SubnetStep(props: { formikProps: FormikProps<CreateOrganisationRequest>, formPropsStuf: any }) {
    const { t } = useTranslation();
    function getRows(organisationRequest: CreateOrganisationRequest): Array<FieldEditorRender[]> {
        return organisationRequest.subnets?.map((subnet) => getSubnetColumns(organisationRequest.hosting_sites, subnet.uuid)) ?? [];
    }


    return (
        <FieldArray
            name="subnets"
            render={arrayHelpers => (
                <EasyFormRow
                    confirmDeletionText={t("Are you sure you wish to remove this subnet?")}
                    {...props.formikProps}
                    {...props.formPropsStuf}
                    editModeAlways={true}
                    editable={true}
                    isDeleteable={true}
                    saveMutation={() => { throw "Dont save plz" }}
                    formType={FormType.LIST}
                    createNew={() => new OrganisationHostingSiteSubnet()}
                    editMode={true}
                    getFieldRows={(createOrganisationRequest) => getRows(createOrganisationRequest as CreateOrganisationRequest)}
                    columns={[
                        new EasyFormColumn("Subnets", "", [])
                    ]}
                    arrayHelpers={arrayHelpers} />
            )
            }
        />
    )
}
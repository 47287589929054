
import getEnvironment from '../../../env';
import { RequestMutationWrapper } from '../../../models/API/RequestMutationWrapper';
import OrganisationFoundation from '../../../models/OrganisationFoundation';
import HandleQuery from '../../../redux/EndpointQueryHandler';
import handleResponse from '../../../redux/handleResponse';
import { portalApiSlice } from '../../../redux/portalApiSlice';
import { transformSingleResponseToRightType } from '../../../redux/transformResponse';

const baseurl = getEnvironment().REACT_APP_API_BASEURL;

export const organisationFoundationApiSlice = portalApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateOrganisationFoundation: builder.mutation<OrganisationFoundation, RequestMutationWrapper<OrganisationFoundation>>({
      query: (request) => HandleQuery({
        url: `${baseurl}/organisation-foundation/${request.data.uuid}`,
        responseHandler: (res) => handleResponse({ response: res, toastSuccessText: "Organisation foundation was updated!", toastErrorText: "Organisation foundation could not be updated" }),
        method: "PATCH",
        body: request.data
      }),
      transformResponse: (a: OrganisationFoundation, b, c) => transformSingleResponseToRightType(OrganisationFoundation, a, b, c),
      invalidatesTags: ['organisationFoundation', 'organisations', 'organisation'],
    }),
  }),
})

export const { useUpdateOrganisationFoundationMutation } = organisationFoundationApiSlice
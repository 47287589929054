import { t } from "i18next";
import * as Yup from 'yup';

export function getOrganisationValidationScheme() {
    return Yup.object().shape({
        name: Yup.string().required(t('Required')),
        bgp_activated: Yup.boolean().required(t('Required')),
        service_monitoring_email: Yup.string().email(t('Invalid email')),
        fallback_email: Yup.string().email(t('Invalid email')).required(t('Required')),
        service_desk_email: Yup.string().nullable().email(t('Invalid email')),
        service_desk_telephone_number: Yup.string().nullable().max(15, t('Max 15 long')).phoneNumber(),
        hotline_telephone_number: Yup.string().nullable().max(15, t('Max 15 long')).phoneNumber(),
        address: Yup.string().max(100 , t("The address is Expected to be max 100 characters"))
    });
}

export function getBaseOrganisationValidationScheme() {
    return Yup.object().shape({
        name: Yup.string().required(t('Required')),
        fallback_email: Yup.string().email(t('Invalid email')).required(t('Required')),
        service_desk_email: Yup.string().nullable().email(t('Invalid email')),
        service_desk_telephone_number: Yup.string().nullable().max(15, t('Max 15 long')).phoneNumber(),
        hotline_telephone_number: Yup.string().nullable().max(15, t('Max 15 long')).phoneNumber(),
        address: Yup.string().max(100 , t("The address is Expected to be max 100 characters"))
    })
}

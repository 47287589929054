import { Chip, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useTranslation } from "react-i18next";
import TooltipComponent from "../TooltipComponent";

class BaseBadgeProps {
    icon?: OverridableComponent<SvgIconTypeMap<object, "svg">>
    content?: JSX.Element
    asText?: boolean = false;
    deepContent?: boolean = false;
    color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    tooltip?: string
    fontSize?: number
    chipSize?: "medium" | "small"
}
export class BadgeProps<T> {
    ressource?: T
    textOverride?: string
    tooltip?: string
    fontSize?: number
    asText?: boolean = false;
    chipSize?: "medium" | "small"
    noLink? = false
}

export function BaseBadge(props: BaseBadgeProps) {
    const { t } = useTranslation();

    const color = props.color == undefined ? props.deepContent ? "info" : "default" : props.color

    let content = <Chip
        size={props.chipSize ?? "medium"}
        color={color}
        icon={props.icon ? <props.icon /> : <></>}
        sx={{ fontSize: props.fontSize ?? 18, padding: 1 }}
        label={props.content} />

    if (props.content == undefined)
        content = (<Typography fontStyle="italic" variant="caption">{t("Missing") + ""}</Typography>)

    if (props.asText) {
        content = <>{props.content}</>;
    }

    return props.tooltip ?
        <TooltipComponent title={t(props.tooltip)}>
            {content}
        </TooltipComponent>
        : content
}
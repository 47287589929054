import BaseModel from "./BaseModels/BaseModel";
import { UserResponse } from "./User";

export class NotificationResponse extends BaseModel {
    notifications?: Notification[]
    un_read?: number
}

export class NotificationPatch extends BaseModel {
    is_read?: boolean
}

export class Notification extends BaseModel {
    agreement_uuid?: string
    created_time?: Date
    da_description?: string
    en_description?: string
    is_read?: boolean
    created_by?: UserResponse
}
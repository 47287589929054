import { FormikProps } from "formik";
import * as Yup from 'yup';
import { EasyFormSideBySide } from "../../../components/Forms/FormLayout";
import { CreateOrganisationRequest } from "../../../models/Organisation";
import getOrganisationFoundationColumns from "../organisationFoundationColumns";
import { getOrganisationFoundationValidationScheme } from "../organisationFoundationSchemeValidation";


export function getOrganisationFoundationStepValidationScheme() {
    return Yup.object().shape({
        organisationFoundation: getOrganisationFoundationValidationScheme()
    })
}

export default function OrganisationFoundationStep(props: { formikProps: FormikProps<CreateOrganisationRequest>, formPropsStuf: any }) {


    return (
        <EasyFormSideBySide
            {...props.formikProps}
            {...props.formPropsStuf}
            columns={ getOrganisationFoundationColumns("organisationFoundation.") }
            editMode={true} />
    )
}

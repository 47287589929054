
import { OperationEnum } from "../../Event"
import { SimpleBaseOrganisation } from "../../Organisation"
import { UserResponse } from "../../User"
import { AddQueryParamsToUrl } from "./AddQueryParamsToUrl"
import { QueryParams } from "./QueryParams"

export class AuditEventQueryPackage implements QueryParams {

    from?: Date
    to?: Date
    entity_uuid?: string
    organisation?: SimpleBaseOrganisation
    operation?: OperationEnum
    audit_user?: UserResponse
    resource?: string
    search_query?: string
    page?: number
    pagesize?: number
    sort_by?: "ORGANISATION" | "OPERATION" | "EVENT_TIME" | "USER" | "ENTITY" | "RESOURCE"
    order_by?: "ASC" | "DESC"

    constructor(queryParams?: {
        from?: Date
        to?: Date
        entity_uuid?: string
        organisation?: SimpleBaseOrganisation
        operation?: OperationEnum
        audit_user?: UserResponse
        resource?: "Service" | "Client"
        search_query?: string
        page?: number
        pagesize?: number
        sort_by?: "ORGANISATION" | "OPERATION" | "EVENT_TIME" | "USER" | "ENTITY" | "RESOURCE"
        order_by?: "ASC" | "DESC"
    }) {
        this.from = queryParams?.from
        this.to = queryParams?.to
        this.entity_uuid = queryParams?.entity_uuid
        this.organisation = queryParams?.organisation
        this.operation = queryParams?.operation
        this.audit_user = queryParams?.audit_user
        this.resource = queryParams?.resource
        this.search_query = queryParams?.search_query
        this.page = queryParams?.page
        this.pagesize = queryParams?.pagesize
        this.sort_by = queryParams?.sort_by
        this.order_by = queryParams?.order_by
    }

    getQuery(currentUrl: string): string {
        return AddQueryParamsToUrl(
            currentUrl,
            [
                "from",
                "to",
                "entity_uuid",
                "organisation_uuid",
                "operation",
                "user_uuid",
                "resource",
                "search_query",
                "page",
                "limit",
                "sort_by",
                "order_by",
            ],
            [
                this.from?.toISOString(),
                this.to?.toISOString(),
                this.entity_uuid,
                this.organisation?.uuid,
                this.operation,
                this.audit_user?.uuid,
                this.resource,
                this.search_query,
                this.page?.toString(),
                this.pagesize?.toString(),
                this.sort_by,
                this.order_by
            ]
        );
    }

}
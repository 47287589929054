import { useState } from 'react';
import { useGetAllOrganisationsQuery } from './redux/organisationApiSlice';

import { Category, Handshake, History, Laptop, Person } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { CountBadge } from '../../components/CountBadge';
import DatagridWrapper, { DataGridId } from '../../components/DatagridWrapper';
import { OpenInNewTabButton } from '../../components/OpenInNewTabButton';
import AgreementRequest from '../../models/Agreement';
import { PaginationQueryPackage } from '../../models/API/QueryParams/PaginationQueryPackage';
import { DetailedOrganisationResponse } from '../../models/Organisation';
import { PermissionEnum } from '../Permission/PermissionEnum';
import { Can } from '../UserApi/logic/Can';
import { OrganisationInfoButton } from './organisationButtons/details/OrganisationInfoButton';

export const OrganisationList = () => {
    return (<OrganisationListContent />)
}

const OrganisationListContent = () => {
    const { t } = useTranslation()

    const [clickedRow, setClickedRow] = useState<AgreementRequest | undefined>(undefined)
    const [uuid, setUuid] = useState(crypto.randomUUID()) //To rerender buttons when clicking a row

    const currentResult = useGetAllOrganisationsQuery({
        pagination: new PaginationQueryPackage()
    });

    if (currentResult.data === undefined)
        currentResult.data = []



    const getColumns = (organisations: DetailedOrganisationResponse[]) => {

        const columns: GridColDef[] = [
            { field: 'name', sortable: true, type: "string", headerName: t('Organisation'), flex: 3 },
            { field: 'description', sortable: true, type: "string", headerName: t('Description'), flex: 4 },
            { field: 'service_desk_email', sortable: true, type: "string", headerName: t('Service desk email'), flex: 2 },
            { field: 'service_desk_telephone_number', sortable: true, type: "string", headerName: t('Service desk telephone number'), flex: 2 },
            { field: 'hotline_telephone_number', sortable: true, type: "string", headerName: t('Hotline telephone number'), flex: 2 },
            { field: 'fallback_email', sortable: true, type: "string", headerName: t('General contact email'), flex: 2 },
            { field: 'ceo_phone', sortable: true, type: "string", headerName: t('Overall contact phone'), valueGetter: (_, row: DetailedOrganisationResponse) => row.organisation_foundation?.ceo_phone, flex: 2 },
            { field: 'security_contact_email', sortable: true, type: "string", headerName: t('Security contact email'), valueGetter: (_, row: DetailedOrganisationResponse) => row.organisation_foundation?.security_manager_email, flex: 2 },
            { field: 'security_contact_phone', sortable: true, type: "string", headerName: t('Security contact phone'), valueGetter: (_, row: DetailedOrganisationResponse) => row.organisation_foundation?.security_manager_phone, flex: 2 },
            { field: 'organisational_contact_email', sortable: true, type: "string", headerName: t('Organisational contact email'), valueGetter: (_, row: DetailedOrganisationResponse) => row.organisation_foundation?.organisational_manager_email, flex: 2 },
            { field: 'organisational_contact_phone', sortable: true, type: "string", headerName: t('Organisational contact phone'), valueGetter: (_, row: DetailedOrganisationResponse) => row.organisation_foundation?.organisational_manager_phone, flex: 2 },
            { field: 'financial_contact_email', sortable: true, type: "string", headerName: t('Financial contact email'), valueGetter: (_, row: DetailedOrganisationResponse) => row.organisation_foundation?.financial_manager_email, flex: 2 },
            { field: 'financial_contact_phone', sortable: true, type: "string", headerName: t('Financial contact phone'), valueGetter: (_, row: DetailedOrganisationResponse) => row.organisation_foundation?.financial_manager_phone, flex: 2 },
            { field: 'services_count', align: "center", renderCell: (params) => <CountBadge prefix={t("services")} icon={Category} count={params.row.services_count} />, sortable: true, type: "number", headerName: t('Services'), flex: 1 },
            { field: 'clients_count', align: "center", renderCell: (params) => <CountBadge prefix={t("clients")} icon={Laptop} count={params.row.clients_count} />, sortable: true, type: "number", headerName: t('Clients'), flex: 1 },
            { field: 'agreements_count', align: "center", renderCell: (params) => <CountBadge prefix={t("agreements")} icon={Handshake} count={params.row.agreements_count} />, sortable: true, type: "number", headerName: t('Agreements'), flex: 1 },
            { field: 'users_count', align: "center", renderCell: (params) => <CountBadge prefix={t("users")} icon={Person} count={params.row.users_count} />, sortable: true, type: "number", headerName: t('Users'), flex: 1 },
            {
                field: 'events', sortable: false, hideable: false, type: "string", valueGetter: () => "", headerName: t('Actions'), minWidth: 220, renderCell: (params => {
                    const organisation = organisations.find(x => x.uuid === params.id)!;
                    return (
                        <>
                            <OrganisationInfoButton
                                key={JSON.stringify(uuid)}
                                openFromStart={organisation.uuid == clickedRow?.uuid}
                                onClose={() => setClickedRow(undefined)}
                                organisationUuid={organisation.uuid!} />
                            <Can I={PermissionEnum.AUDIT_READ} this={organisation}>
                                <OpenInNewTabButton tooltipText='Events' icon={<History />} url={`/events?entity_uuid=${organisation.uuid}`} />
                            </Can>
                            <OpenInNewTabButton url={`/organisations/${organisation.uuid}`} />
                        </>
                    )
                })
            }
        ];
        return columns
    }

    return (
        <DatagridWrapper
            dataGridId={DataGridId.ORGANISATIONS}
            getRowId={(row) => row.uuid}
            autoHeight
            loading={currentResult.isLoading}
            onRowClick={(row) => { setClickedRow(row.row); setUuid(crypto.randomUUID()) }}
            rows={currentResult.data}
            columns={getColumns(currentResult.data)}
            initialColumnVisibilityModel={{
                fallback_email: false,
                ceo_phone: false,
                security_contact_email: false,
                security_contact_phone: false,
                organisational_contact_email: false,
                organisational_contact_phone: false,
                financial_contact_email: false,
                financial_contact_phone: false,
            }}
        />
    )
}


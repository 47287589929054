import { FieldEditorRender } from "./FieldEditorRender";
import { FieldRenderType } from "./FieldRenderType";

export class FieldHeaderRenderPackage {
    displayText!: string
}

export class FieldHeaderRender extends FieldEditorRender {
    constructor(fieldHeaderRenderPackage: FieldHeaderRenderPackage) {
        const fieldPackage = {
            uniqueId: "",
            fieldName: "",
            ... fieldHeaderRenderPackage
        }
        super(FieldRenderType.Header, fieldPackage);
    }
}
import { useContext } from "react";
import EasyForm from "../../../../../components/Forms/EasyForm";
import { FormType } from "../../../../../components/Forms/EasyFormMany";
import { DetailedOrganisationResponse } from "../../../../../models/Organisation";
import { PermissionEnum } from "../../../../Permission/PermissionEnum";
import { AbilityContext } from "../../../../UserApi/logic/Can";
import getOrganisationFoundationColumns from "../../../organisationFoundationColumns";
import { getOrganisationFoundationValidationScheme } from "../../../organisationFoundationSchemeValidation";
import { useUpdateOrganisationFoundationMutation } from "../../../redux/organisationFoundationApiSlice";


interface OrganisationFoundationTabProps {
  organisation: DetailedOrganisationResponse
}


export default function OrganisationFoundationTab(props: OrganisationFoundationTabProps) {
  const ability = useContext(AbilityContext)

  return (

      <EasyForm
        isAllowedToEdit={() => ability.can(PermissionEnum.GOD, props.organisation)}
        formType={FormType.INFORMATION}
        arrayPath=""
        SignupSchema={getOrganisationFoundationValidationScheme}
        initialValue={props.organisation.organisation_foundation}
        saveMutation={useUpdateOrganisationFoundationMutation}
        columns={ getOrganisationFoundationColumns() }
        getFieldRows={() => []}
        createNew={function () {
          throw new Error("Function not implemented.");
        }}
      />
  )
}




import EasyFormColumn from "../../components/Forms/EasyFormColumn";
import { FieldHeaderRender } from "../../components/Forms/Renders/FieldHeaderRender";
import { TextFieldRender } from "../../components/Forms/Renders/TextFieldRender";


export default function getOrganisationFoundationColumns(fieldPrefix?: string) {
    return ([
        new EasyFormColumn("Organisation foundation", "", getColumn1(fieldPrefix)),
        new EasyFormColumn("\u200B", "", getColumn2(fieldPrefix)) // \u200B is a zero-width space to make sure the columns are aligned
    ])
}

const getColumn1 = (fieldPrefix?: string) => {
    return [
        new TextFieldRender({
            displayText: "CVR number",
            fieldName: (fieldPrefix ?? "") + "cvr",
            type: "text",
            uniqueId: ""
        }),
        new FieldHeaderRender({ displayText: "Overall contact / CEO" }),
        new TextFieldRender({
            displayText: "Title and name",
            fieldName: (fieldPrefix ?? "") + "ceo_name",
            type: "text",
            uniqueId: ""
        }),
        new TextFieldRender({
            displayText: "Phone",
            fieldName: (fieldPrefix ?? "") + "ceo_phone",
            type: "text",
            uniqueId: ""
        }),
        new TextFieldRender({
            displayText: "Email",
            fieldName: (fieldPrefix ?? "") + "ceo_email",
            type: "text",
            uniqueId: ""
        }),
        new FieldHeaderRender({ displayText: "Security manager" }),
        new TextFieldRender({
            displayText: "Title and name",
            fieldName: (fieldPrefix ?? "") + "security_manager_name",
            type: "text",
            uniqueId: ""
        }),
        new TextFieldRender({
            displayText: "Phone",
            fieldName: (fieldPrefix ?? "") + "security_manager_phone",
            type: "text",
            uniqueId: ""
        }),
        new TextFieldRender({
            displayText: "Email",
            fieldName: (fieldPrefix ?? "") + "security_manager_email",
            type: "text",
            uniqueId: ""
        }),
        new FieldHeaderRender({ displayText: "Organisational manager" }),
        new TextFieldRender({
            displayText: "Title and name",
            fieldName: (fieldPrefix ?? "") + "organisational_manager_name",
            type: "text",
            uniqueId: ""
        }),
        new TextFieldRender({
            displayText: "Phone",
            fieldName: (fieldPrefix ?? "") + "organisational_manager_phone",
            type: "text",
            uniqueId: ""
        }),
        new TextFieldRender({
            displayText: "Email",
            fieldName: (fieldPrefix ?? "") + "organisational_manager_email",
            type: "text",
            uniqueId: ""
        }),
    ];
}

const getColumn2 = (fieldPrefix?: string) => {
    return [
        new FieldHeaderRender({ displayText: "Financial manager" }),
        new TextFieldRender({
            displayText: "Title and name",
            fieldName: (fieldPrefix ?? "") + "financial_manager_name",
            type: "text",
            uniqueId: ""
        }),
        new TextFieldRender({
            displayText: "Phone",
            fieldName: (fieldPrefix ?? "") + "financial_manager_phone",
            type: "text",
            uniqueId: ""
        }),
        new TextFieldRender({
            displayText: "Email",
            fieldName: (fieldPrefix ?? "") + "financial_manager_email",
            type: "text",
            uniqueId: ""
        }),
        new FieldHeaderRender({ displayText: "Service desk and on-call" }),
        new TextFieldRender({
            displayText: "Phone",
            fieldName: (fieldPrefix ?? "") + "service_desk_phone",
            type: "text",
            uniqueId: ""
        }),
        new TextFieldRender({
            displayText: "Email",
            fieldName: (fieldPrefix ?? "") + "service_desk_email",
            type: "text",
            uniqueId: ""
        }),
    ]
}
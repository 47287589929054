import { FieldArray, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import EasyFormColumn from "../../../components/Forms/EasyFormColumn";
import { FormType } from "../../../components/Forms/EasyFormMany";
import { EasyFormRow } from "../../../components/Forms/FormLayout";
import { FieldEditorRender } from "../../../components/Forms/Renders/FieldEditorRender";
import { TextFieldRender } from "../../../components/Forms/Renders/TextFieldRender";
import { CreateOrganisationHostingSiteRequest, CreateOrganisationRequest } from "../../../models/Organisation";
import { getHostingSiteValidationScheme } from "../../HostingSiteApi/hostingSiteSchemeValidation";

export function getHostingSiteStepValidation() {
    return Yup.object().shape({
        hosting_sites: Yup.array().of(getHostingSiteValidationScheme())
    })
}

export default function HostingSiteStep(props: { formikProps: FormikProps<CreateOrganisationRequest>, formPropsStuf: any }) {
    const { t } = useTranslation();

    function getRows(organisationRequest: CreateOrganisationRequest): Array<FieldEditorRender[]> {
        return organisationRequest.hosting_sites?.map((hosting_site) => getHostingSiteColums(hosting_site.uuid)) ?? [];
    }

    return (
        <FieldArray
            name="hosting_sites"
            render={arrayHelpers => (
                <EasyFormRow
                    confirmDeletionText={t("Are you sure you wish to remove this hosting site?")}
                    {...props.formikProps}
                    {...props.formPropsStuf}
                    editModeAlways={true}
                    editable={true}
                    isDeleteable={true}
                    saveMutation={() => { throw "Dont save plz" }}
                    formType={FormType.LIST}
                    createNew={() => new CreateOrganisationHostingSiteRequest()}
                    editMode={true}
                    getFieldRows={(createOrganisationRequest) => getRows(createOrganisationRequest as CreateOrganisationRequest)}
                    columns={[
                        new EasyFormColumn("Hosting sites", "", [])
                    ]}
                    arrayHelpers={arrayHelpers} />
            )
            }
        />
    )
}

export function getHostingSiteColums(uniqueId: string): FieldEditorRender[] {
    return ([
        new TextFieldRender({
            displayText: "Name",
            fieldName: "hosting_sites",
            showLabel: true,
            nestedfieldName: "name",
            type: "text",
            columns: 12,
            uniqueId: uniqueId
        }),
        new TextFieldRender({
            displayText: "Address",
            fieldName: "hosting_sites",
            showLabel: true,
            nestedfieldName: "address",
            type: "text",
            columns: 12,
            uniqueId: uniqueId
        }),
    ])
}
import { ErrorCodeEnum } from "./ErrorCodeEnum";

export const GenericErrorMessage = "An error occured";

export const ErrorCodeMessages: Record<ErrorCodeEnum, string> = {
    [ErrorCodeEnum.GENERIC]: GenericErrorMessage,
    [ErrorCodeEnum.BAD_REQUEST]: GenericErrorMessage,
    [ErrorCodeEnum.RESOURCE_NOT_FOUND]: "Requested resource not found.",
    [ErrorCodeEnum.UUID_DOES_NOT_MATCH]: GenericErrorMessage,
    [ErrorCodeEnum.INVALID_PAGINATION]: "Invalid pagination parameters.",
    [ErrorCodeEnum.INVALID_FIELD_VALUE]: GenericErrorMessage,
    [ErrorCodeEnum.MISSING_PARAMETER]: GenericErrorMessage,
    [ErrorCodeEnum.MISSING_IP_ADDRESSES_ON_CLIENT]: "Missing ip addresses on client.",
    [ErrorCodeEnum.INVALID_AGREEMENT_STATUS]: "Invalid agreement status.",
    [ErrorCodeEnum.UNPROCESSABLE_ENTITY]: GenericErrorMessage,
    [ErrorCodeEnum.CONFLICT]: "Conflict detected.",
    [ErrorCodeEnum.SUBNET_OVERLAP]: "Subnet overlap detected.",
    [ErrorCodeEnum.RESOURCE_NOT_ACCESSIBLE]: "Resource not accessible.",
    [ErrorCodeEnum.AUDIT_API_ERROR]: GenericErrorMessage,
    [ErrorCodeEnum.UNKNOWN_USER]: "Unknown user.",
    [ErrorCodeEnum.INVALID_TOKEN]: "Invalid token.",
    [ErrorCodeEnum.SERVICE_WINDOW_UPDATE_NOT_ALLOWED]: "Service window update not allowed.",
    [ErrorCodeEnum.REDUNDANT_REQUEST]: GenericErrorMessage,
    [ErrorCodeEnum.ORGANISATION_NOT_FOUND]: "Organization not found.",
    [ErrorCodeEnum.SERVICE_NOT_FOUND]: "Service not found.",
    [ErrorCodeEnum.SERVICE_WINDOW_NOT_FOUND]: "Service window not found.",
    [ErrorCodeEnum.BAD_GATEWAY]: GenericErrorMessage,
    [ErrorCodeEnum.SUBNET_NOT_FOUND]: "Subnet not found.",
    [ErrorCodeEnum.HOSTING_SITE_NOT_FOUND]: "Hosting site not found.",
    [ErrorCodeEnum.SUBNET_IN_USE]: "Subnet is already in use.",
    [ErrorCodeEnum.INVALID_SERVICE_APPROVER]: "Invalid service approver.",
    [ErrorCodeEnum.CLIENT_NOT_FOUND]: "Client not found.",
    [ErrorCodeEnum.EXTERNAL_CONTACT_NOT_FOUND]: "External contact not found.",
    [ErrorCodeEnum.PORTALIAS_NOT_FOUND]: "Portal alias not found.",
    [ErrorCodeEnum.USER_NOT_FOUND]: "User not found.",
    [ErrorCodeEnum.USER_NOTIFICATION_NOT_FOUND]: "User notification not found.",
    [ErrorCodeEnum.IP_ADDRESS_NOT_FOUND]: "Ip address not found.",
    [ErrorCodeEnum.SMOKEPING_NOT_FOUND]: "Smokeping not found.",
    [ErrorCodeEnum.ROUTER_NOT_FOUND]: "Router not found.",
    [ErrorCodeEnum.AGREEMENT_NOT_FOUND]: "Agreement not found.",
    [ErrorCodeEnum.DNS_SUFFIX_NOT_FOUND]: "DNS suffix not found.",
    [ErrorCodeEnum.CONTACT_LABEL_NOT_FOUND]: "Contact label not found.",
    [ErrorCodeEnum.SERVICE_MONITORING_NOT_FOUND]: "Service monitoring not found.",
    [ErrorCodeEnum.SERVICETAG_NOT_FOUND]: "Service tag not found.",
    [ErrorCodeEnum.INVALID_SERVICE_TAG]: "Invalid service tag.",
    [ErrorCodeEnum.OPERATION_REQUIRES_FORCE]: "The values provided requires enforcement",
    [ErrorCodeEnum.REASON_FOR_NO_ENCRYPTION_REQUIRED]: "Reason for no encryption required.",
    [ErrorCodeEnum.SERVICE_WINDOW_MUST_HAVE_SERVICE]: "Service window must have a service.",
    [ErrorCodeEnum.SERVICE_NOT_AT_SERVICE_WINDOW]: "Service not at service window.",
    [ErrorCodeEnum.CONTACT_NOT_FOUND]: "Contact not found.",
    [ErrorCodeEnum.DUPLICATE_CONTACT]: "Duplicate contact found.",
    [ErrorCodeEnum.ENDPOINT_NOT_FOUND]: "Endpoint not found.",
    [ErrorCodeEnum.CONFLICTING_EXTERNAL_CONTACT]: "Conflicting external contact.",
    [ErrorCodeEnum.CONFLICTING_USER]: "Conflicting user.",
    [ErrorCodeEnum.INVALID_CONTACT_LABEL]: "Invalid contact label.",
    [ErrorCodeEnum.CONTACT_NOT_CLIENT_CONTACT]: GenericErrorMessage,
    [ErrorCodeEnum.CONTACT_NOT_SERVICE_CONTACT]: GenericErrorMessage,
    [ErrorCodeEnum.PASSWORD_AND_USERNAME_SHOULD_NOT_BE_PROVIDED]: "Both password and username should not be provided.",
    [ErrorCodeEnum.BOTH_PASSWORD_AND_USERNAME_SHOULD_BE_PROVIDED]: "Both password and username should be provided.",
    [ErrorCodeEnum.KEYCLOAK_ERROR]: "Keycloak error.",
    [ErrorCodeEnum.USER_IS_NOT_SERVICE_APPROVER_ON_SERVICE]: "User is not a service approver on the service.",
    [ErrorCodeEnum.ENDPOINT_ON_SERVICE_WITH_APPROVED_AGREEMENTS]: "Endpoint on a service with approved agreements.",
    [ErrorCodeEnum.DNS_SUFFIX_IN_USE_AT_HOSTING_SITE]: "DNS suffix is already in use at hosting site.",
    [ErrorCodeEnum.EMAIL_NOT_SENT]: "Email not sent.",
    [ErrorCodeEnum.USER_ALREADY_HAS_AUTHORISATION_TO_ORGANISATION]: "User already has authorization to the organization.",
    [ErrorCodeEnum.USER_CANNOT_HAVE_AUTHORISATION_TO_OWN_ORGANISATION]: "User cannot have authorization to own organization.",
    [ErrorCodeEnum.PARENTUSER_IS_AN_AUTHORISATIONUSER]: GenericErrorMessage,
    [ErrorCodeEnum.USER_NOT_ALLOWED_TO_USE_PROVIDED_AUTHORISATION]: "User not allowed to use the provided authorization.",
    [ErrorCodeEnum.SETTING_HIGHER_PRIVILEGES_THAN_OWN_USER]: "Not allowed to give more privileges than own role.",
    [ErrorCodeEnum.CONTACT_NOT_ORGANISATION_CONTACT]: GenericErrorMessage,
    [ErrorCodeEnum.USER_DOES_NOT_BELONG_TO_ORGANISATION]: "User does not belong to the organization.",
    [ErrorCodeEnum.INVALID_DNS_NAME]: "Invalid DNS name.",
    [ErrorCodeEnum.HOSTING_SITE_NAME_ALREADY_IN_USE]: "Hosting site name already in use.",
    [ErrorCodeEnum.ORGANISATION_NAME_ALREADY_IN_USE]: "Organization name already in use.",
    [ErrorCodeEnum.PORTALIAS_IS_IN_USE]: "Portal alias is already in use.",
    [ErrorCodeEnum.SERVICE_TAG_ALREADY_ON_HOSTING_SITE]: "Service tag already exists on the hosting site.",
    [ErrorCodeEnum.HOSTING_SITE_SDN_V4_ID_ALREADY_IN_USE]: "Hosting site SDN V4 ID already in use.",
    [ErrorCodeEnum.ACCESS_CONTROL_LIST_NOT_FOUND]: "Access control list not found.",
    [ErrorCodeEnum.COPY_FROM_USER_FROM_DIFFERENT_ORGANISATION]: "Cannot to user from a different organisation.",
    [ErrorCodeEnum.USER_ALREADY_SERVICE_APPROVER_ON_SERVICE]: "User is already servive approver.",
    [ErrorCodeEnum.CPE_NOT_FOUND]: "CPE not found",
    [ErrorCodeEnum.SUBNET_MOVE_INVALID_SERVICES]: "Move failed. Services in subnet could not be moved.",
    [ErrorCodeEnum.SUBNET_MOVE_INVALID_CLIENTS]: "Move failed. Clients in subnet could not be moved.",
    [ErrorCodeEnum.EXPECTED_MATCHING_DOMAIN_NAME]: "Deletion failed. Expected atleast another endpoint with a matching domain name.",
    [ErrorCodeEnum.USER_CANNOT_DELETE_ITS_OWN_ORGANISATION]: "User cannot delete its own organisation.",
    [ErrorCodeEnum.CANNOT_DELETE_ORGANISATION_WITH_APPROVED_AGREEMENTS]: "Cannot delete organisation with approved agreements.",
    [ErrorCodeEnum.CANNOT_DELETE_ORGANISATION_WHILE_ADMINISTRATING_HOSTING_SITES]: "Cannot delete organisation that adminstrates hosting sites",
    [ErrorCodeEnum.CANNOT_DELETE_OWN_USER]: "Cannot delete own user",
    [ErrorCodeEnum.MISSING_REQUIRED_CONTACT_LABEL]: "Required contact label is missing",
    [ErrorCodeEnum.ORGANISATION_FOUNDATION_NOT_FOUND]: "Organisation foundation not found",
};

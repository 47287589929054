import { Business } from "@mui/icons-material";
import OrganisationResponse from "../../models/Organisation";
import { OpenInNewTabButton } from "../OpenInNewTabButton";
import { BadgeProps, BaseBadge } from "./BaseBadge";

export function OrganisationBadge(props: BadgeProps<OrganisationResponse>) {
    if (props.textOverride)
        return <BaseBadge {...props} content={<>{props.textOverride}</>} icon={Business} />

    const content = props?.ressource?.name
    const icon = <BaseBadge deepContent={!props.noLink} {...props} content={<>{content}</>} icon={Business} />
    return props.noLink ? icon : <OpenInNewTabButton url={`/organisations/${props?.ressource?.uuid}`} icon={icon} padding={0} />
}